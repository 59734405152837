const hexToRgb = (h) => {
  if (!h) return [];

  try {
    return h
      .replace(
        /^#?([\da-f])([\da-f])([\da-f])$/i,
        (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`
      )
      .slice(1)
      .match(/.{2}/g)
      .map((x) => Number.parseInt(x, 16));
  } catch (error) {
    console.error(error);
  }

  return [];
};

export default hexToRgb;
