export const DEFAULT_LANGUAGE = 'en';

export const PROMO_BANNER_CLASS_NAMES = {
  trial: 'trial-promo',
  partner: 'partner-promo',
  freemium: 'freemium-promo',
};

export const PROMO_BANNER_TITLE = '_promo-banner-title';
export const PROMO_BANNER_IMAGE = '_promo-banner-image';
export const PROMO_BANNER_DESCRIPTION = '_promo-banner-description';
export const PROMO_BANNER_LINK = '_promo-banner-link';
